<template>
  <header class="tui tuim app header fixed">
    <div class="tui tuim ui massive menu navbar">
      <a href="#" class="tui tuim logo item" @click="goToDash">
        <t-logo-svg />
      </a>

      <t-nav />

      <nav class="right menu">
        <!-- <t-notifications /> -->
        <t-user />
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'THeader',
  methods: {
    goToDash (e) {
      e.preventDefault()
      this.$router.push({ name: 'dashboard' })
    }
  },
  components: {
    TLogoSvg: () => import('@/components/header/logo.vue'),
    TNav: () => import('@/components/header/nav.vue'),
    TUser: () => import('@/components/header/user.vue')
    // TNotifications: () => import('@/components/header/notifications.vue')
  }
}
</script>

<style lang="scss">
@import "collect/header";
@import "collect/navbar";

.tui.tuim.app.header {
  &-enter-active,
  &-leave-active {
    transform: translate3d(0, 0, 0);
    transition: opacity .2s ease-in-out, transform .12s ease-in-out;
  }

  &-enter,
  &-leave-to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  a.item.logo {
    &, &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
