var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "tui tuim app header fixed" }, [
    _c(
      "div",
      { staticClass: "tui tuim ui massive menu navbar" },
      [
        _c(
          "a",
          {
            staticClass: "tui tuim logo item",
            attrs: { href: "#" },
            on: { click: _vm.goToDash }
          },
          [_c("t-logo-svg")],
          1
        ),
        _c("t-nav"),
        _c("nav", { staticClass: "right menu" }, [_c("t-user")], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }